import React, { useState } from 'react'
import { graphql } from 'gatsby'
import Slider from "react-slick"
import uuid from 'uuid'
import ReactBnbGallery from 'react-bnb-gallery'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarAlt, faClock, faTag } from '@fortawesome/free-solid-svg-icons'


import Layout from '../components/Layout'
import SEO from '../components/seo'
import BgImage from "../components/BgImage"
import BookingForm from "../components/BookingForm"
import RelatedActivities from '../components/RelatedActivities'
import ScrollToBtn from '../components/ScrollToBtn';
import useWindowSize from '../utils/useWindowSize';


const ActivityTemplate = ({data}) => {

  const windowSize = useWindowSize(1000)

  const [galleryOpened, setGalleryOpened] = useState(false)

  const activity = data.markdownRemark.frontmatter;
  const images = data.markdownRemark.frontmatter.images

  const gallery_pics = images.map(({childImageSharp}) => {
    return {
      photo: childImageSharp.fluid.srcWebp
    }
  })

  return (
    <Layout sidebarComponent={<RelatedActivities active_id={data.markdownRemark.id} />} className="detail-page activity-page" sidebarTriggerTitle="Other activities">
      <SEO title=""/>

      {
        (windowSize.windowWidth > 1000) &&
      
        <section className="row-2-cols">
          <div className="one-half" onClick={() => setGalleryOpened(true) }>
            <BgImage className='detail-pic detail-pic-big' image={images[0]} />
          </div>
    
          <div className="one-half">
            <div onClick={() => setGalleryOpened(true) }>
              <BgImage className='detail-pic' image={images[1]} />
            </div>
            <div className="row-2-cols">
              <div className="one-half" onClick={() => setGalleryOpened(true) }>
                <BgImage className='detail-pic' image={images[2]} />
              </div>
              <div className="one-half" onClick={() => setGalleryOpened(true) }>
                <BgImage className='detail-pic' image={images[3]} />
              </div>
            </div>
          </div>
        </section>
      }

      {
        (windowSize.windowWidth <= 1000) &&

        <Slider
          dots={true}
          slidesToScroll={1}
          slidesToShow={1}
          className="detail-slider"
        >
          {images.map((slide) => (
            <div onClick={() => setGalleryOpened(true) } key={uuid.v4()}>
              <BgImage
                className='detail-pic'
                image={slide}
              />
            </div>
          ))}
        </Slider>  
      }


      <div className="detail-page-wrapper">
        <div className="detail-page-header">
          <div className="row">
            <div className="col-md-6">
              <h1>{activity.title} - <span>{data.markdownRemark.frontmatter.price} <span>/ {data.markdownRemark.frontmatter.duration}</span></span></h1>
            </div>
            <div className="col-md-6 text-right">
              <ScrollToBtn
                title={<>Book this activity <FontAwesomeIcon icon={faCalendarAlt} /></>}
                className="booking-btn" 
                destination=".booking-form-wrapper"
                overflowedDiv=".page-content"
              />
            </div>   
          </div>
        </div>
        
        <div className="inner-content detail-description" dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }}></div>

      </div>

      <div className="booking-form-wrapper block-bg-img">
        <div className="inner-content">
          <BookingForm
            hideProductType={true}
            productType="Activity"
            defaultProduct={data.markdownRemark.frontmatter.title}
          />
        </div>
      </div>            

      <ReactBnbGallery
        show={galleryOpened}
        photos={gallery_pics}
        onClose={() => setGalleryOpened(false)}
      />

    </Layout>
  )

}

export default ActivityTemplate

export const ActivityPageQuery = graphql`
  query ($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      id
      html
      frontmatter {
        title
        type
        path
        duration
        price
        departure
        images {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1000) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`

